import picture202204042 from "../../assets/202204042.jpg";
import picture202204041 from "../../assets/202204041.jpg";
import picture202111231 from "../../assets/202111231.jpg";
import picture202111221 from "../../assets/202111221.jpg";
import picture202111222 from "../../assets/202111222.jpg";
import picture202109141 from "../../assets/202109141.jpg";
import picture202203141 from "../../assets/202203141.jpg";
import picture202109061 from "../../assets/202109061.jpg";
import picture202102101 from "../../assets/202102101.jpg";

import Button from "@mui/material/Button";

import { Element } from "react-scroll";
import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import {
  CardWrapper,
  GridContainer,
  NewsContainer,
  NewsHeader,
  TextHeaderPictureWrapper,
  CardTopMarginWrapper2,
  ReadMoreContentStyle,
  Paragraph,
} from "./news.components";
import { useState } from "react";
import Collapse from "../utils/Collpase";
import CollpaseNews from "../utils/CollpaseNews";

const News: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Element name="aktualnosci">
      <NewsHeader className="heading-text">Aktualności</NewsHeader>
      <NewsContainer>
        <GridContainer container spacing={2}>
          <CollpaseNews visibleItems={2}>
            <Grid
              item
              xs={12}
              lg={5}
              xl={5}
              rowSpacing={1}
              data-aos="fade-left"
            >
              <CardWrapper>
                <CardMedia component="img" image={picture202204041} />
                <CardContent>
                  <TextHeaderPictureWrapper>
                    Opublikowano: 2022-04-04
                  </TextHeaderPictureWrapper>

                  <Typography variant="body2" color="text.secondary">
                    Ostatnie prace jakie wykonywalismy polegaly na usunieciu
                    brzóz w celu oczyszczenia terenu pod budowe domu. Dzialka
                    zostala przygotowana do dalszych etapów prac budowlanych.
                  </Typography>
                </CardContent>
              </CardWrapper>
            </Grid>

            <Grid
              item
              xs={12}
              lg={4}
              xl={4}
              rowSpacing={1}
              data-aos="fade-right"
            >
              <Card>
                <CardMedia component="img" image={picture202111231} />
                <CardContent>
                  <TextHeaderPictureWrapper>
                    Opublikowano: 2021-11-23
                  </TextHeaderPictureWrapper>
                  <Typography variant="body2" color="text.secondary">
                    Czyszczenie działki przygotowujące do dalszych prac
                    budowlanych. Zakres prac obejmował karczowanie samosiejek
                    drzew liściastych, owocowych, usunięcie karp, oraz
                    definitywne wyrównanie terenu do dalszych etapów
                    zagospodarowania przestrzeni zielonej.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              xl={4}
              rowSpacing={1}
              data-aos="fade-left"
            >
              <Card>
                <CardMedia component="img" image={picture202204042} />
                <CardContent>
                  <TextHeaderPictureWrapper>
                    Opublikowano: 2022-04-04
                  </TextHeaderPictureWrapper>
                  <Typography variant="body2" color="text.secondary">
                  <Collapse open={isExpanded} firstHeightContent={60}>
                  Niedawno mielismy okazje usuwac szkody, jakie wyrzadzily
                      powalone drzewa na budynki, drogi, oraz tereny prywatne po
                      przejsciu nietypowego, ale bardzo niszczycielskiego orkanu
                      Eunice. W zakres naszych uslug wchodzi usuwanie
                      wiatrolomów z budynków, sprzatanie i wywóz odpadów
                      zielonych, które utylizujemy. Dobrym krokiem jest dbanie o
                      wielkosci naszych drzew na bierzaco, usuwanie nadmiernie
                      rozrastajacych sie galezi kiedy wymagaja one pielegnacji,
                      lub sekcjonowanie obumarlego posuszu, bowiem nie
                      pielegnowane drzewo moze wyrzadzic wiecej szkód, niz jesmy
                      sobie w stanie to wyobrazic.
                      </Collapse>
                      <Button
                      variant="text"
                      color="success"
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      {!isExpanded ? " Czytaj dalej.." : " Zwiń.."}
                    </Button>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4} xl={4} rowSpacing={1} data-aos="fade-top">
              <Card>
                <CardMedia component="img" image={picture202111221} />
                <CardContent>
                  <TextHeaderPictureWrapper>
                    Opublikowano: 2021-11-22
                  </TextHeaderPictureWrapper>
                  <Typography variant="body2" color="text.secondary">
                    Realizacja przygotowania wymagającego pagórkowatego terenu
                    pod inwestycje, wyburzenie starych elementów fundamentów na
                    działce, karczowanie lasu samosiejek drzew liściastych oraz
                    finalnie wyrównanie gruntu.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              xl={4}
              rowSpacing={1}
              data-aos="fade-right"
            >
              <CardTopMarginWrapper2>
                <Card>
                  <CardMedia component="img" image={picture202111222} />
                  <CardContent>
                    <TextHeaderPictureWrapper>
                      Opublikowano: 2021-11-22
                    </TextHeaderPictureWrapper>
                    <Typography variant="body2" color="text.secondary">
                      <Collapse open={isExpanded} firstHeightContent={60}>
                        Wycinka metodą alpinistyczną to metoda zastosowania
                        technik alpinistycznych przy pracach z zakresu
                        przycinania i wycinania drzew. Wprowadzenie tych technik
                        daje możliwość szybszego i o wiele bezpieczniejszego
                        wykonywania wycinkowych zadań szczególnie w trudno
                        dostępnych miejscach, gdzie nie sprawdzają się
                        tradycyjne metody.
                      </Collapse>
                      <Button
                        variant="text"
                        color="success"
                        onClick={() => setIsExpanded(!isExpanded)}
                      >
                        {!isExpanded ? " Czytaj dalej.." : " Zwiń.."}
                      </Button>
                    </Typography>
                  </CardContent>
                </Card>
              </CardTopMarginWrapper2>
            </Grid>
            <Grid item xs={12} lg={4} xl={4} rowSpacing={1}>
              <Card>
                <CardMedia component="img" image={picture202109141} />
                <CardContent>
                  <TextHeaderPictureWrapper>
                    Opublikowano: 2021-09-14
                  </TextHeaderPictureWrapper>
                  <Typography variant="body2" color="text.secondary">
                    Porządkowanie terenu pod inwestycje budowlaną, karczowanie,
                    wycinka manualna, usługi mulczerem leśnym.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4} xl={4} rowSpacing={1}>
              <Card>
                <CardMedia component="img" image={picture202203141} />
                <CardContent>
                  <TextHeaderPictureWrapper>
                    Opublikowano: 2021-03-14
                  </TextHeaderPictureWrapper>
                  <Typography variant="body2" color="text.secondary">
                    <Collapse open={isExpanded} firstHeightContent={0}>
                      <Paragraph>
                        Ziemia Kwiatowa, Kora sosnowa, Piasek na wymianę
                      </Paragraph>
                      <Paragraph>
                        Oferujemy sprzedaż gotowych podłoży ogrodniczych, kory
                        ozdobnej, piasku z dostawa do klienta. Obecnie oferta
                        dotyczy ziemi ogrodniczej, kory sosnowej oraz piasku
                        służącemu do wymiany piaskownic:
                      </Paragraph>

                      <Paragraph>
                        - ZIEMIA OGRODNICZA UNIWERSALNA to produkt ekonaturalny.
                        SKŁADNIKI: ziemia torfowa wysoko organiczna, kompozycja
                        torfu wysokiego i niskiego, pył z kory, piasek
                        ziarnisty, PH 5,7-6. Zastosowanie: - ziemia nadaje się
                        do zakładania i renowacji trawników, boisk, rabatek, -
                        jak również do uprawy kwiatów i warzyw, - a także do
                        nasadzania, przesadzania oraz zasilania większości
                        rodzajów roślin. Towar pakowany jest w worki foliowe o
                        pojemności zasypowej 60l. CENA: 7.99 ZŁ
                      </Paragraph>

                      <Paragraph>
                        - KORA SOSNOWA OZDOBNA Korę sosnową najczęściej
                        stosujemy do ściółkowania stref roślinnych pod iglakami,
                        obsypywania skarp i skalniaków. Zalecana grubość warstwy
                        od 5 - 10 cm Zastosowanie: - ściółkowanie stref
                        roślinnych, - zakwaszanie gleby, - utrzymywanie
                        wilgotności podłoża, - zapobieganie nadmiernemu
                        wzrostowi chwastów, - doskonała okrywa cieplna na zimę.
                        Towar pakowany jest w worki foliowe o pojemności
                        zasypowej 60l. CENA: 8.99 ZŁ
                      </Paragraph>

                      <Paragraph>
                        - PIASEK ATESTOWANY Piasek sortowany i płukany,
                        kopalniany, kwarcowy. Produkt oferowany przez nas
                        posiada atest Państwowego Zakładu Higieny - czyli
                        posiada badania: bakteriologiczne, sanitarne,
                        higieniczne oraz promieniotwórcze. Zastosowanie: wymiana
                        piasku w piaskownicach, Towar sprzedajemy luzem, tzn.
                        dowóz od 1 tony w cenie od 80 zł,
                      </Paragraph>
                      <Paragraph>
                        Oferta ważna do 2022-03-14 – 2022-03-31
                      </Paragraph>
                    </Collapse>
                    <Button
                      variant="text"
                      color="success"
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      {!isExpanded ? " Czytaj dalej.." : " Zwiń.."}
                    </Button>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4} xl={4} rowSpacing={1}>
              <Card>
                <CardMedia component="img" image={picture202109061} />
                <CardContent>
                  <TextHeaderPictureWrapper>
                    Opublikowano: 2021-09-06
                  </TextHeaderPictureWrapper>
                  <Typography variant="body2" color="text.secondary">
                    PROMOCJA Ziemia ogrodnicza 7,99 zł/60 l, Kora sosnowa 8,99
                    zł/60 l, piasek na wymianę piaskownic 80 zł/ tona, HALINÓW
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4} xl={4} rowSpacing={1}>
              <Card>
                <CardMedia component="img" image={picture202102101} />
                <CardContent>
                  <TextHeaderPictureWrapper>
                    Opublikowano: 2021-02-10
                  </TextHeaderPictureWrapper>
                  <Typography variant="body2" color="text.secondary">
                    <ReadMoreContentStyle>
                      <Collapse open={isExpanded} firstHeightContent={0}>
                        <Paragraph>
                          Karczowanie działek budowlano-inwestycyjnych
                        </Paragraph>
                        <Paragraph>
                          Karczowanie, czyszczenie działek np. pod budowę domu
                          lub innego rodzaju inwestycji.
                        </Paragraph>
                        <Paragraph>
                          Dzięki odpowiedniemu zapleczu technicznemu i
                          posiadanym wieloletnim umiejętnościom jesteśmy w
                          stanie wyciąć każde drzewo, wypielęgnować je, oraz
                          wykarczować każdą działkę!
                        </Paragraph>

                        <Paragraph>
                          Prace mogą być wykonywane przy pomocy podnośnika
                          koszowego, wywrotki, oraz HDSu, a także samodzielnie w
                          trudno dostępnych lokacjach.
                        </Paragraph>
                        <Paragraph>
                          Aby wycenić dany obiekt umów nie na bezpłatne
                          oględziny Twojej lokacji!
                        </Paragraph>
                        <Paragraph>
                          Zapraszamy do kontaktu tel. 660 680 168
                        </Paragraph>
                        <Paragraph>
                          Oferta ważna do 2021-02-10 – 2021-02-17
                        </Paragraph>
                      </Collapse>

                      <Button
                        variant="text"
                        color="success"
                        onClick={() => setIsExpanded(!isExpanded)}
                      >
                        {!isExpanded ? " Czytaj dalej.." : " Zwiń.."}
                      </Button>
                    </ReadMoreContentStyle>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </CollpaseNews>
        </GridContainer>
      </NewsContainer>
    </Element>
  );
};

export default News;
