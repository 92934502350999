/* eslint-disable @typescript-eslint/no-unused-vars */
import { Element } from "react-scroll";
import officePlace from "../../assets/office_place.jpg";
import Phone from "../../assets/phone.png";
import Email from "../../assets/email.png";
import Address from "../../assets/address.png";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Contactbackground,
  ContactButton,
  ContactContainer,
  ContactDesc,
  ContactForm,
  ContactHeader,
  ContactIcon,
  ContactInfoItem,
  ContactInput,
  ContactLeft,
  ContactMainWrapper,
  ContactMapImg,
  ContactRight,
  ContactTextArea,
  ContactTitle,
  ContactWrapper,
  EmailWrapper,
  MapContainer,
} from "./contact.components";
import { useRef, useState } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const form = useRef<HTMLFormElement>(null);
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    const currentForm = form.current;
    if (currentForm == null) return;
    emailjs
      .sendForm(
        "service_my19vca",
        "template_ta0dv4s",
        currentForm,
        "user_6pV4SCctlpzlVEgmkRHrG"
      )
      .then(
        (result) => {
          setDone(!done);
          setLoading(false);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current?.reset();
  };

  return (
    <Element name="kontakt">
      <ContactContainer>
        <ContactHeader className="heading-text">Kontakt</ContactHeader>
        <MapContainer>
          <div>
            <a
              href="https://www.google.com/maps/place/DANIELAK+Daniel+Anielak+wycinka+i+piel%C4%99gnacja+drzew%2F+czyszczenie+dzia%C5%82ek%2F+us%C5%82ugi+podno%C5%9Bnikiem+koszowym%2F+handel+drzewem/@52.2234629,21.3634331,17z/data=!3m1!4b1!4m5!3m4!1s0x471ed710654b51af:0x810da63f5705ce26!8m2!3d52.2234629!4d21.3634331"
              target="_blank"
              rel="noreferrer"
            >
              <ContactMapImg src={officePlace} alt="mapa_biura" />
            </a>
          </div>
        </MapContainer>

        <ContactMainWrapper className="contact">
          <Contactbackground className="contact-bg"></Contactbackground>
          <ContactWrapper className="contact-wrapper">
            <ContactLeft className="contact-left">
              <ContactTitle className="contact-title">
                Skontaktuj sie z nami:
              </ContactTitle>
              <div className="contact-info">
                <ContactInfoItem className="contact-info-item">
                  <ContactIcon src={Phone} alt="" className="contact-icon" />
                  <a href="tel:660 680 168"> +48 660 680 168</a>
                </ContactInfoItem>
                <ContactInfoItem className="contact-info-item">
                  <ContactIcon className="contact-icon" src={Email} alt="" />
                  <EmailWrapper href="mailto:biuro@pielegnujemydrzewa.pl">
                    biuro@pielegnujemydrzewa.pl
                  </EmailWrapper>
                </ContactInfoItem>
                <ContactInfoItem className="contact-info-item">
                  <ContactIcon className="contact-icon" src={Address} alt="" />
                  <a href="https://www.google.com/maps/dir//DANIELAK+Daniel+Anielak+wycinka+i+piel%C4%99gnacja+drze/data=!4m8!4m7!1m0!1m5!1m1!1s0x471ed710654b51af:0x810da63f5705ce26!2m2!1d21.363433099999998!2d52.223462899999994">
                    Halinów
                  </a>
                </ContactInfoItem>
              </div>
            </ContactLeft>
            <ContactRight className="contact-right">
              <ContactDesc className="contact-desc">
                <b>Uzupełnij formularz kontaktowy</b> W polu email wpisz adres
                kontaktowy, na który prześlemy odpowiedź lub podaj numer
                telefonu.
              </ContactDesc>
              <ContactForm ref={form} onSubmit={handleSubmit}>
                <ContactInput
                  type="text"
                  placeholder="Imię"
                  name="user_name"
                  required={true}
                />
                <ContactInput
                  type="text"
                  placeholder="Temat"
                  name="user_subject"
                  required={true}
                />
                <ContactInput
                  type="text"
                  placeholder="Twój email"
                  name="user_email"
                />
                <ContactInput
                  type="text"
                  placeholder="Twój nr tel"
                  name="user_phone"
                />
                <ContactTextArea
                  placeholder="Wiadomość"
                  name="message"
                  required={true}
                />
                <div>
                  <ContactButton>
                    {!loading ? (
                      "Wyślij"
                    ) : (
                      <div>
                        <CircularProgress color="inherit" />
                      </div>
                    )}
                  </ContactButton>
                </div>

                {done && <div>Dziękujemy! Wiadomość została wysłana. </div>}
              </ContactForm>
            </ContactRight>
          </ContactWrapper>
        </ContactMainWrapper>
      </ContactContainer>
    </Element>
  );
};
export default Contact;
