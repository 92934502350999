import { NotFoundContainer } from "./notfound.components";
import { NotFoundProps } from "./notfound.d";

const NotFound: React.FC<NotFoundProps> = () => {
  return (
    <NotFoundContainer>Unfortunately 404 Page not found</NotFoundContainer>
  );
};

export default NotFound;
