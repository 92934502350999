import { CopyrightContainer } from "./copyright.components";

const Copyright: React.FC = () => {
  return (
    <CopyrightContainer>
      Ⓒ Wszystkie prawa zastrzeżone. Danielak 2023
    </CopyrightContainer>
  );
};

export default Copyright;
