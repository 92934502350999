import styled from "styled-components";

export const ReviewsContainer = styled.div`
  padding-top: 50px;

  .RootLayout__Component-sc-1doisyz-0 {
    z-index: 0;
  }

  .eapps-widget-toolbar {
    display: none;
  }
`;

export const ReviewsHeader = styled.h1`
  display: flex;
  justify-content: center;
`;

export const SpanWrapper = styled.span`
  font-weight: 100;
`;
