import styled from "styled-components";

export const Container = styled.div`
  z-index: 999998;
  position: absolute;
  top: -50px;
  background-color: white;
  width: 100%;
  height: 750px;
`;
