import styled from "styled-components";

export const ContactContainer = styled.div`
  font-size: 1.5rem;
  @media (max-width: 420px) {
    padding-left: 0;
  }
`;

export const MapsBackground = styled.div`
  background-image: url("https://maps.googleapis.com/maps/api/staticmap?scale=1&amp;size=1600x900&amp;style=feature:poi.business|visibility:off&amp;style=feature:water|visibility:simplified&amp;style=feature:road|element:labels.icon|visibility:off&amp;style=feature:road.highway|element:labels|saturation:-90|lightness:25&amp;format=jpg&amp;language=pl&amp;region=PL&amp;markers=color:0x88b06a|52.2234629,21.3634331&amp;zoom=16&amp;client=google-presto&amp;signature=XZDJ0CzrqP_SHsa3TA--VQOVAXI");
`;

export const MapContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContactMapImg = styled.img`
  display: flex;
  justify-content: center;
  width: 80;
  width: 100%;
  height: 80%;
  @media (max-width: 420px) {
    background-size: cover;
    width: 100%;
  }
`;

export const ContactHeader = styled.h2`
  display: flex;
  justify-content: center;
`;

export const ContactMainWrapper = styled.div`
  height: 100vh;
  position: relative;
`;

export const Contactbackground = styled.div`
  width: 20px;
  height: 100%;
  background-color: #a0d911;
  position: absolute;
`;

export const ContactWrapper = styled.div`
  padding: 50px;
  display: flex;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    padding: 0px 50px;
  }
`;

export const ContactLeft = styled.div`
  flex: 1;
`;

export const ContactRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContactTitle = styled.h1`
  font-size: 60px;
  width: 80%;
  @media screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

export const ContactInfoItem = styled.div`
  display: flex;
  align-items: center;
  margin: 50px 0px;
  font-weight: 300;
  width: 100%;

  @media screen and (max-width: 480px) {
    margin: 20px 0px;
    width: 100%;
    font-size: 16px;
  }
`;

export const ContactIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;

export const ContactDesc = styled.p`
  font-weight: 200;
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const ContactForm = styled.form`
  margin-top: 20px;
  @media screen and (max-width: 480px) {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const ContactInput = styled.input`
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 1px solid black;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 40px;
    margin: 10px;
    margin-left: 0;
  }
`;

export const ContactTextArea = styled.textarea`
  width: 100%;
  height: 70px;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
`;

export const ContactButton = styled.button`
  border: none;
  padding: 15px;
  background-color: #a0d911;
  color: white;
  font-weight: 500;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    margin-top: 10px;
  }
`;

export const EmailWrapper = styled.a`
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;
