import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../../assets/logo_danielak2.png";
import { Element } from "react-scroll";

import {
  NavigationContainer,
  NavigationMenu,
  NavigationMenuMobile,
  StyledLink,
} from "./navigation.components";

interface Page {
  label: string;
  key: string;
}

const pages: Page[] = [
  { label: "Aktualności", key: "aktualnosci" },
  { label: "O Nas", key: "o_nas" },
  { label: "Opinie", key: "opinie" },
  { label: "Galeria", key: "galeria" },
  { label: "Kontakt", key: "kontakt" },
];

const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Navigation = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Element name="navigatgionn">
      <AppBar position="static">
        <NavigationContainer>
          <Container maxWidth="xl">
            <Toolbar className="ToolbarClass">
              <Typography
                className="TypographyClass"
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <img src={Logo} width="270" height="140" />
                {/* Mobile */}
              </Typography>

              <Box
                sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                className="BoxClassName"
              >
                <IconButton
                  className="IconButtonClass"
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon className="MenuIconClass" />
                </IconButton>
                <Menu
                  className="MenuClass"
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  <NavigationMenuMobile className="NavigationMobileClassName">
                    {pages.map((page) => (
                      <StyledLink
                        style={{ padding: "0 64px 24px 64px" }}
                        className="StyledLinkClassName"
                        smooth={true}
                        offset={50}
                        duration={1500}
                        delay={200}
                        to={page.key}
                        key={page.key}
                        onClick={handleCloseNavMenu}
                      >
                        {page.label}
                      </StyledLink>
                    ))}
                  </NavigationMenuMobile>
                </Menu>
              </Box>
              <Typography
                variant="h4"
                noWrap
                component="a"
                href=""
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontSize: "34px",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <img src={Logo} width="270" height="140" />
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {/* //Desktop */}
                <NavigationMenu>
                  {pages.map((page) => (
                    <StyledLink
                      smooth={true}
                      offset={50}
                      duration={1500}
                      delay={200}
                      to={page.key}
                      key={page.key}
                      onClick={handleCloseNavMenu}
                    >
                      {page.label}
                    </StyledLink>
                  ))}
                </NavigationMenu>
              </Box>

              <Box sx={{ flexGrow: 0 }} style={{ right: "32px" }}>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Toolbar>
          </Container>
        </NavigationContainer>
      </AppBar>
    </Element>
  );
};
export default Navigation;
