import { Paper, Button } from "@mui/material";
import React from "react";
import { Element, Link } from "react-scroll";
import { Item } from "./Carousel";
import {
  CallButtonWrapper,
  CallIcon,
  ItemContainer,
  TextContainer,
} from "./item.components";

interface ItemComponentProps {
  item: Item;
}

const ItemComponent: React.FC<ItemComponentProps> = ({
  item: { name, description, background, secondBackround },
}) => {
  return (
    <Paper>
      <ItemContainer
        backgroundImage={background}
        secondBackgroundImage={secondBackround}
      >
        <TextContainer>
          <h2>{name}</h2>
          <p>{description}</p>
        </TextContainer>
        <Element name="kontakt">
          <Link className="CheckButton" to={"contact"} key={"contact"}>
            <Button variant="contained" color="success">
              Poproś o wycenę!
            </Button>
          </Link>
          <CallButtonWrapper>
            <a href="tel:660 680 168">
              <CallIcon fontSize="large" />
            </a>
          </CallButtonWrapper>
        </Element>
      </ItemContainer>
    </Paper>
  );
};

export default ItemComponent;
