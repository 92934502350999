import { useEffect } from "react";
import { Element } from "react-scroll";
import { ReviewsContainer, ReviewsHeader } from "./reviews.components";

const Reviews = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apps.elfsight.com/p/platform.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // zapnij ref na tego dziwnego diva i useEffect na refie i przeszukujesz w poszukiwaniu takiego selectora href^="https://elfsight.com/"

  return (
    <Element name="opinie">
      <ReviewsContainer>
        <ReviewsHeader>Opinie</ReviewsHeader>
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
        <div className="elfsight-app-5ed84120-bf72-4415-b5ff-a99215656efa"></div>
      </ReviewsContainer>
    </Element>
  );
};
export default Reviews;
