import {
  AboutCardBg,
  AboutCard,
  AboutContainer,
  AboutAwardTexts,
  AboutAwardDesc,
  AboutAwardTitle,
  AboutAwardImgRight,
  AboutDesc,
  AboutAward,
  AboutTitleWrapper,
  AboutImgLeft,
  LeftAos,
  RightAos,
} from "./about.components";

import itCrowdImg from "../../assets/1.jpg";
import picture202109141 from "../../assets/202109141.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

import { useEffect } from "react";
import { Element } from "react-scroll";

const aosDealyDuration = 2000;

const About: React.FC = () => {
  useEffect(() => {
    Aos.init({ duration: aosDealyDuration });
  }, []);
  return (
    <Element name="o_nas">
      <div>
        <AboutContainer>
          <LeftAos data-aos="fade-right" className="about-left">
            <AboutCardBg className="about-card-bg"></AboutCardBg>
            <AboutCard className="about-card">
              <AboutImgLeft src={itCrowdImg} alt="" className="about-img" />
            </AboutCard>
          </LeftAos>
          <RightAos data-aos="fade-left" className="about-right">
            <AboutTitleWrapper>CO NAS WYRÓŻNIA ?</AboutTitleWrapper>
            <AboutDesc className="about-desc">
              <p>
                Dzięki odpowiedniemu zapleczu technicznemu i posiadanym
                wieloletnim umiejętnościom jesteśmy w stanie wyciąć każde
                drzewo, wypielęgnować je, oraz wykarczować każdą działkę!
              </p>
              <p>
                Oferujemy konkurencyjne ceny! Bezpłatnie wyceniamy każde
                zlecenie, indywidualna wycena po wstępnych oględzinach!
              </p>
            </AboutDesc>
            <AboutAward className="about-award">
              <AboutAwardImgRight
                src={picture202109141}
                alt=""
                className="about-award-img"
              />
              <AboutAwardTexts className="about-award-texts">
                <AboutAwardTitle className="about-award-title">
                  UMÓW SIĘ NA BEZPŁATNĄ WYCENĘ!
                </AboutAwardTitle>
                <AboutAwardDesc className="about-award-desc">
                  Profesjonalna pielęgnacja i wycinka drzew. Doświadczeni
                  arboryści. Halinów
                  <p>Otwarte dziś do 21:00</p>
                </AboutAwardDesc>
              </AboutAwardTexts>
            </AboutAward>
          </RightAos>
        </AboutContainer>
      </div>
    </Element>
  );
};

export default About;
