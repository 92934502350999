import { Button } from "@mui/material";
import React, { useMemo, useState } from "react";
import { StyledDiv } from "../gallery/gallery.components";

interface CollpasePictureProps {
  children: React.ReactNode[];
  visibleItems: number;
}

const ADDING_NUMBER_ITEM = 3;
const CollpasePicture = ({ children }: CollpasePictureProps) => {
  const [currentShown, setCurrentShown] = useState<number>(1);

  const maxShowCount = useMemo(
    () => (children as React.ReactNode[])?.length / ADDING_NUMBER_ITEM,
    [children]
  );

  const couldShowMore = useMemo(
    () => currentShown < maxShowCount,
    [currentShown, maxShowCount]
  );

  const handleShowMore = () => {
    if (currentShown < maxShowCount) setCurrentShown(currentShown + 1);
  };

  return (
    <>
      {(children || []).slice(
        0,
        currentShown * ADDING_NUMBER_ITEM + ADDING_NUMBER_ITEM
      )}
      <>
        {couldShowMore && (
          <StyledDiv
            style={{
              justifyContent: "center",
            }}
          >
            <Button onClick={handleShowMore} style={{ color: "white" }}>
              <b>Pokaż więcej...</b>
            </Button>
          </StyledDiv>
        )}
      </>
    </>
  );
};

export default CollpasePicture;
