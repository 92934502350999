import React, { useRef } from "react";

interface CollapseProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  firstHeightContent: number;
  children: React.ReactNode | React.ReactNode[];
  transition?: number;
  open: boolean;
}

const Collapse = ({
  open,
  children,
  transition = 200,
  firstHeightContent,
}: CollapseProps) => {
  const collapseItem = useRef<HTMLDivElement | null>(null);
  const hasManyChildrens = Array.isArray(children);

  return (
    <>
      {hasManyChildrens && children[0]}
      <div
        ref={collapseItem}
        style={{
          maxHeight: open
            ? collapseItem?.current?.scrollHeight
            : firstHeightContent,
          transition: `${transition}ms`,
          overflow: "hidden",
        }}
      >
        {hasManyChildrens ? children.slice(1, children.length) : children}
      </div>
    </>
  );
};

export default Collapse;
