import { Outlet } from "react-router-dom";
import Navigation from "../navigation/Navigation";

const Main: React.FC = () => {
  return (
    <div className="page">
      <Navigation />
      <Outlet />
      <footer></footer>
    </div>
  );
};

export default Main;
