import React from "react";
import Carousel from "react-material-ui-carousel";
import ItemComponent from "./Item";
import carouselBackround1 from "../../assets/carouselBackround1.png";
import carouselBackround2 from "../../assets/carouselBackround2.png";
import carouselBackround3 from "../../assets/carouselBackround3.png";
import CoverNavigationPanel from "../panels/CoverNavigationPanel";
import { CarouselContainer } from "./item.components";

export interface Item {
  name: string;
  description: string;
  background: string;
  secondBackround: string;
}

const items: Item[] = [
  {
    background:
      "https://images.pexels.com/photos/11597980/pexels-photo-11597980.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    name: "Pielęgnacja i wycinka drzew",
    description: "Zadbaj o swoją przestrzeń",
    secondBackround: carouselBackround1,
  },
  {
    background:
      "https://images.pexels.com/photos/2310483/pexels-photo-2310483.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    name: "Czyszczenie działek, usługi podnośnikiem",
    description: "Najlepszy sprzęt",
    secondBackround: carouselBackround2,
  },
  {
    background:
      "https://images.pexels.com/photos/3619797/pexels-photo-3619797.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    name: "Doświadczeni arboryści",
    description: "Zaufaj specjalistom",
    secondBackround: carouselBackround3,
  },
];

const PhotoCarousel: React.FC = () => {
  return (
    <>
      <CarouselContainer>
        <Carousel autoPlay>
          {items.map((item, i) => (
            <ItemComponent key={i} item={item} />
          ))}
        </Carousel>
      </CarouselContainer>

      <CoverNavigationPanel />
    </>
  );
};

export default PhotoCarousel;
