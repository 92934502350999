import styled from "styled-components";

export const GalleryContainer = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  & .container {
    padding: 40px 5%;
  }

  .heading-text {
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  .heading-text span {
    font-weight: 100;
  }

  ul {
    list-style: none;
  }

  /* Responsive image gallery rules begin*/

  .image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .image-gallery > li {
    flex: 1 1 auto; /* or flex: auto; */
    height: 300px;
    cursor: pointer;
    position: relative;
  }

  .image-gallery::after {
    content: "";
    flex-grow: 999;
  }

  .image-gallery li img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    border-radius: 5px;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(57, 57, 57, 0.502);
    top: 0;
    left: 0;
    transform: scale(0);
    transition: all 0.2s 0.1s ease-in-out;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-gallery li:hover .overlay {
    transform: scale(1);
  }

  .image-gallery li img:hover .image-gallery {
    transform: scale(3);
  }
`;

export const AboutPicture4 = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const GalleryHeader = styled.h2`
  display: flex;
  justify-content: center;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  background-color: green;
`;

// https://codesandbox.io/s/sts6l7?file=/style.css:255-1242
// https://blog.logrocket.com/how-create-responsive-image-gallery-css-flexbox/
