import { Button } from "@mui/material";
import React, { useMemo, useState } from "react";
import { StyledDiv } from "../news/news.components";

interface CollpasePictureProps {
  children: React.ReactNode[];
  visibleItems: number;
}

const GALLERY_VISIBLE_ITEMS = 2;
const CollpasePicture = ({ children, visibleItems }: CollpasePictureProps) => {
  const [currentShown, setCurrentShown] = useState<number>(0);

  const maxShowCount = useMemo(
    () => (children as React.ReactNode[])?.length / visibleItems,
    [children, visibleItems]
  );

  const couldShowMore = useMemo(
    () => currentShown < maxShowCount,
    [currentShown, maxShowCount]
  );

  const handleShowMore = () => {
    if (currentShown < maxShowCount) setCurrentShown(currentShown + 1);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        {(children || []).slice(
          0,
          currentShown * GALLERY_VISIBLE_ITEMS + GALLERY_VISIBLE_ITEMS
        )}
      </div>

      <>
        {couldShowMore && (
          <StyledDiv
            style={{
              justifyContent: "center",
            }}
          >
            <Button onClick={handleShowMore} style={{ color: "white" }}>
              <b>Pokaż więcej...</b>
            </Button>
          </StyledDiv>
        )}
      </>
    </div>
  );
};

export default CollpasePicture;
