import {
  AboutPicture4,
  GalleryContainer,
  GalleryHeader,
} from "./gallery.components";
import { Element } from "react-scroll";

import picture1 from "../../assets/1.jpg";
import picture202204042 from "../../assets/202204042.jpg";
import picture202204041 from "../../assets/202204041.jpg";
import picture202111221 from "../../assets/202111221.jpg";
import picture202111222 from "../../assets/202111222.jpg";
import picture202109141 from "../../assets/202109141.jpg";
import picture202102101 from "../../assets/202102101.jpg";
import picture11 from "../../assets/11.jpg";
import picture12 from "../../assets/12.jpg";
import picture13 from "../../assets/13.jpg";
import picture15 from "../../assets/15.jpg";
import picture16 from "../../assets/16.jpg";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import * as React from "react";
import CollpaseGallery from "../utils/CollpaseGallery";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface PictureToShow {
  open: boolean;
  picture: string;
}

const Gallery = () => {
  const [pictureToShow, setPictureToShow] = React.useState<PictureToShow>({
    open: false,
    picture: "",
  });

  const handleClickOpen = (picture: string) => {
    setPictureToShow({
      open: true,
      picture,
    });
  };

  const handleClose = () => {
    setPictureToShow({
      ...pictureToShow,
      open: false,
    });
  };

  return (
    <GalleryContainer>
      <Element name="galeria">
        <div className="container">
          <GalleryHeader className="heading-text">Galeria</GalleryHeader>
          <ul className="image-gallery">
            <CollpaseGallery visibleItems={4}>
              <li
                onClick={() => {
                  handleClickOpen(picture1);
                }}
              >
                <AboutPicture4 src={picture1} alt="wycinka_drzew" />
                <div className="overlay">
                  <span>Profesjonalna wycinka drzew</span>
                </div>
              </li>
              <li
                onClick={() => {
                  handleClickOpen(picture202204042);
                }}
              >
                <AboutPicture4 src={picture202204042} alt="drabina sprzet" />
                <div className="overlay">
                  <span>Usuwanie szkód, które spowodowały drzewa</span>
                </div>
              </li>
              <li
                onClick={() => {
                  handleClickOpen(picture202204041);
                }}
              >
                <AboutPicture4 src={picture202204041} alt="" />
                <div className="overlay">
                  <span>Oczyszczanie terenu pod budowę</span>
                </div>
              </li>
              <li
                onClick={() => {
                  handleClickOpen(picture13);
                }}
              >
                <AboutPicture4 src={picture13} alt="" />
                <div className="overlay">
                  <span>Usługi pielęgnacyjne</span>
                </div>
              </li>
              <li
                onClick={() => {
                  handleClickOpen(picture202111221);
                }}
              >
                <AboutPicture4 src={picture202111221} alt="" />
                <div className="overlay">
                  <span>Karczowanie lasu i wyrównanie gruntu</span>
                </div>
              </li>
              <li
                onClick={() => {
                  handleClickOpen(picture202111222);
                }}
              >
                <AboutPicture4 src={picture202111222} alt="" />
                <div className="overlay">
                  <span>Wycinka metodą alpinistyczną</span>
                </div>
              </li>
              <li
                onClick={() => {
                  handleClickOpen(picture202109141);
                }}
              >
                <AboutPicture4 src={picture202109141} alt="" />
                <div className="overlay">
                  <span>Porządkowanie terenu pod inwestycje</span>
                </div>
              </li>
              <li
                onClick={() => {
                  handleClickOpen(picture11);
                }}
              >
                <AboutPicture4 src={picture11} alt="" />
                <div className="overlay">
                  <span>Transport rzeczy</span>
                </div>
              </li>

              <li
                onClick={() => {
                  handleClickOpen(picture16);
                }}
              >
                <AboutPicture4 src={picture16} alt="" />
                <div className="overlay">
                  <span>Usługi wysokościowe</span>
                </div>
              </li>
              <li
                onClick={() => {
                  handleClickOpen(picture202102101);
                }}
              >
                <AboutPicture4 src={picture202102101} alt="" />
                <div className="overlay">
                  <span>Cośtam</span>
                </div>
              </li>
              <li
                onClick={() => {
                  handleClickOpen(picture15);
                }}
              >
                <AboutPicture4 src={picture15} alt="" />
                <div className="overlay">
                  <span>Piękna maszyna</span>
                </div>
              </li>
            </CollpaseGallery>
          </ul>
        </div>
      </Element>
      <Dialog
        open={pictureToShow.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "80%!important",
          },
        }}
      >
        <DialogContent>
          <AboutPicture4 src={pictureToShow.picture} alt="wycinka_drzew" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Zamknij</Button>
        </DialogActions>
      </Dialog>
    </GalleryContainer>
  );
};

export default Gallery;
