import { Link } from "react-scroll";
import styled from "styled-components";

export const NavigationContainer = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  background: white;
  display: flex;
  align-items: left;
  justify-content: left;
  color: green;

  :scroll-behavior {
    visibility: hidden;
  }

  :scroll-padding-top {
    visibility: hidden;
  }
`;

export const NavigationMenu = styled.div`
  position: sticky;

  background: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 30px 70px 0 70px;

  @media (max-width: 768px) {
    color: green;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const NavigationMenuMobile = styled.div`
  position: sticky;

  background: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 30px;
  font-weight: 400;

  @media (max-width: 768px) {
    color: green;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 100%;
  }
`;

export const StyledLink = styled(Link)`
  color: black;
  font-size: 34px;
  font-weight: 600;

  :hover {
    transform: scale(1.1);
    color: green;
  }
`;
