import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Contact from "./components/contact/Contact";
import Gallery from "./components/gallery/Gallery";
import Home from "./components/home/Home";
import Main from "./components/main/Main";
import News from "./components/news/News";
import NotFound from "./components/notfound/NotFound";
import Reviews from "./components/reviews/Reviews";


function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route index element={<Home />} />
        <Route path="news" element={<News />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="reviews" element={<Reviews />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
