import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import styled from "styled-components";
interface ItemCenterProps {
  backgroundImage: string;
  secondBackgroundImage: string;
}

const heightCarouselItem = "700px";

export const CallIcon = styled(LocalPhoneIcon)``;

export const CarouselContainer = styled.div`
  .css-ohwg9z {
    z-index: 999999;
  }
`;

export const ItemContainer = styled.div<ItemCenterProps>`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  color: green;
  text-align: center;
  height: ${heightCarouselItem};

  @media (max-width: 420px) {
    background-image: url(${(props) => props.secondBackgroundImage});
  }
`;

export const CallButtonWrapper = styled.div`
  @media (max-width: 2900px) {
    visibility: hidden;
  }
  @media (max-width: 420px) {
    .MuiSvgIcon-root {
      width: 50px;
      height: 50px;
      font-size: 80px;
      border-style: solid;
      border-color: green;
      padding: 11px;
      border-radius: 50%;
      border-width: 4px;
      background-color: rgb(160, 217, 17);
      color: white;

      :hover {
        transform: scale(1.5);
      }
    }

    position: absolute;
    bottom: 10%;
    left: 40%;
    visibility: visible;
  }
`;

export const TextContainer = styled.div`
  padding-top: 10%;
  color: white;
  font-size: 48px;

  @media (max-width: 1536px) {
    font-size: 46px;
  }

  @media (max-width: 420px) {
    font-size: 32px;
  }
`;
