import { Card, Grid } from "@mui/material";
import styled from "styled-components";

export const NewsContainer = styled.div`
  margin-top: 50px;
  padding: 40px;
  background-image: url("https://images.pexels.com/photos/1083515/pexels-photo-1083515.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  // background-size: calc(100vw - (2 * 35px)) calc(100vh - (2 * 25px));
  // background-size: calc(100vw - (2 * 35px)) calc(100vh);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* filter: brightness(50%); */
  & img {
    webkit-box-shadow: 0 25px 26px -6px #777;
    -moz-box-shadow: 0 25px 26px -6px #777;
    box-shadow: 0 25px 26px -6px #777;
  }
`;

export const TextHeaderPictureWrapper = styled.div`
  font-size: 28px;
  font-family: Helvetica, Arial, Sans-Serif;
  color: green;
  margin-bottom: 24px;
`;

export const CardWrapper = styled(Card)`
  box-shadow: -1px 1px 5px 20px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
`;

export const CardTopMarginWrapper2 = styled(Card)`
  @media (min-width: 1200px) {
    position: relative;
    margin-top: 50px;
  }
`;

export const CardTopMarginWrapper3 = styled(Card)`
  @media (min-width: 1200px) {
    position: relative;
    margin-top: 25px;
  }
`;

export const CardTopMarginWrapper4 = styled(Card)`
  @media (min-width: 1200px) {
    position: relative;
    margin-top: 25px;
  }
`;

export const NewsHeader = styled.h1`
  display: flex;
  justify-content: center;
  padding-top: 55px; ;
`;

export const GridContainer = styled(Grid)`
  justify-content: center;
`;

export const ReadMoreContentStyle = styled.div`
  text-align: left;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Paragraph = styled.p``;
