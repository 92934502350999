import styled from "styled-components";

export const AboutContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow-x: hidden;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    margin-top: 5px;
  }
`;

export const JustifyCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeftAos = styled(JustifyCenter)`
  flex: 1;
  position: relative;
  height: 100%;

  @media (max-width: 1500px) {
    margin-top: 0;
  }

  @media (max-width: 1200px) {
    margin-top: 0;
  }

  @media (max-width: 900px) {
    margin-top: 0;
  }

  @media (max-width: 600px) {
    margin-top: 0;
  }
`;

export const RightAos = styled.div`
  flex: 1;
  @media (max-width: 600px) {
    position: relative;
    margin-top: 0;
  }
`;

export const AboutCard = styled.div`
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: relative;
  overflow: hidden;

  @media (max-width: 1200px) {
    margin-top: 0;
    width: 60%;
    height: 50vh;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
  }

  @media (max-width: 900px) {
    margin-top: 0;
    width: 60%;
    height: 40vh;
    border-radius: 20px;
    overflow: hidden;
  }

  @media (max-width: 600px) {
    width: 80%;
    height: 80vh;
    border-radius: 15px;
    overflow: hidden;
  }
`;

export const AboutImgLeft = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 1200px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 50%;
    object-fit: cover;
  }

  @media (max-width: 420px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const AboutCardBg = styled.div`
  position: absolute;
  background-color: #333;
`;

export const AboutSub = styled.p`
  font-size: 22px;
  margin: 20px 0px;
`;

export const AboutDesc = styled.p`
  padding-right: 150px;
  font-size: 22px;
  font-weight: 300;
  @media (max-width: 768px) {
    padding-right: 10px;
    padding-left: 10px;
  }
  @media (max-width: 420px) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

export const AboutAwardDesc = styled(AboutDesc)`
  padding-left: 20px;
`;

export const AboutTitleWrapper = styled.h1`
  font-size: 30px;
  color: black;
  font-weight: 400px;
  @media (max-width: 420px) {
  }
`;

export const AboutAward = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1200px) {
    margin-top: 0px;
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
`;

export const AboutAwardImgRight = styled.img`
  width: 30%;
  height: 30%;
  border-radius: 20px;

  @media (max-width: 900px) {
    position: relative;
    margin-top: 0;
  }

  @media (max-width: 600px) {
    position: relative;
    top: 10px;
    width: 90%;
    height: 90%;
    border-radius: 20px;
  }

  @media (max-width: 420px) {
    position: flex;
  }
`;
export const AboutAwardTexts = styled.div`
  width: 70%;
`;

export const AboutAwardTitle = styled.h4`
  font-size: 20px;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
  padding-left: 20px;
`;
