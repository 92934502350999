import About from "../about/About";
import PhotoCarousel from "../carousel/Carousel";
import Contact from "../contact/Contact";
import Gallery from "../gallery/Gallery";
import News from "../news/News";

import Reviews from "../reviews/Reviews";
import { HomeProps } from "./home.d";
import BottomReviewsPanel from "../panels/BottomReviewsPanel";
import { HomeContainer } from "./home.components";
import Copyright from "../copyright/Copyright";

const Home: React.FC<HomeProps> = () => {
  return (
    <>
      <HomeContainer>
        <PhotoCarousel />
        <About />
        <News />
        <Reviews />
        <BottomReviewsPanel />
        <Gallery />
        <Contact />
      </HomeContainer>
      <Copyright />
    </>
  );
};

export default Home;
